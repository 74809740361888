/* You can add global styles to this file, and also import other style files */
.mat-menu-panel {
  background-color: aqua;
}
.mat-menu-panel.els-menu .mat-menu-item.selected-item {
  background-color: #ff00c2;
  color: #fff;
}
.mat-menu-panel.els-menu .mat-clear-btn {
  display: none;
}
.mat-menu-panel.els-menu .mat-menu-content {
  max-height: 450px;
  overflow: scroll;
}
.mat-menu-panel.els-menu.els-menu-with-clear {
  position: relative;
}
.mat-menu-panel.els-menu.els-menu-with-clear .mat-menu-content {
  padding-bottom: 40px;
}
.mat-menu-panel.els-menu.els-menu-with-clear .mat-clear-btn {
  position: absolute;
  bottom: 0;
  background-color: #e2e2e2;
  display: inline-flex;
}